import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";
import SvgComponent from "./SvgComponent";

const ShareUrlPopup = ({ isOpenPop, setIsOpenPop, id, image }) => {
  if (!isOpenPop) return null;
    const linkToCopy = `https://360.xix3d.com/${id}`;
  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
      style={{ zIndex: 7 }}
    >
      <div
        className="reserve-my-spot-instantly-popup"
        style={{ backgroundColor: "white" }}
      >
        <button
          type="button"
          className="btn-close-icon"
          onClick={() => {
            setIsOpenPop(false);
          }}
        >
          <span className="material-icons"> close </span>
        </button>
        <img
          className="mx-auto mb-6"
          src={image}
          alt="image not supported"
        />
        <div className="text-center img-container">
          <div className="mb-6">
            <h1 className="text-center font-semibold">
              Share it with your friends
            </h1>
            <p className="text-slate-500 mb-0 text-sm">
              Copy the link or use social media to share this project
            </p>
          </div>
          <div className="mb-6 cursor-pointer" onClick={handleCopyLink}>
            <p className="font-medium mb-0 text-sm copy-link">Copy Link</p>
          </div>
          <div className="mb-6">
            <p className="text-slate-500 mb-0 text-sm">
              or use the social buttons
            </p>
          </div>
          <div className="Demo__container">
            <div className="Demo__some-network">
              <FacebookShareButton
                title={"XX SHOWROOM 360"}
                url={`${process.env.REACT_APP_URL}/${id}`}
                className="Demo__some-network__share-button"
              >
                <SvgComponent name={"FaceBookIcon"} />
              </FacebookShareButton>
            </div>
            <div className="Demo__some-network">
              <TwitterShareButton
                url={`${process.env.REACT_APP_URL}/${id}`}
                title={"XX SHOWROOM 360"}
                className="Demo__some-network__share-button"
              >
                <SvgComponent name={"TwitterShareIcon"} />
              </TwitterShareButton>
            </div>
            <div
              className="Demo__some-network cursor-pointer"
              onClick={handleCopyLink}
            >
              <SvgComponent name={"InstaShareIcon"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareUrlPopup;
